import React, { useState, useCallback, useRef } from 'react';
import { GoogleMap, Polygon, InfoWindow, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '600px', // Made taller
  borderRadius: '12px',
  overflow: 'hidden',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
};

const LocationMap = ({ data }) => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [hoveredRegion, setHoveredRegion] = useState(null);
  const mapRef = useRef(null);
  const boundsSet = useRef(false);
  
  // Get all regions
  const regions = data[0]?.regions || [];
  
  // Transform all coordinates for each region and keep region data
  const allRegions = regions.map(region => {
    const coordinates = region.geofence?.coordinates || [];
    return {
      ...region,
      paths: coordinates.map(coordSet => 
        coordSet[0].map(coord => ({
          lat: parseFloat(coord[1]),
          lng: parseFloat(coord[0])
        }))
      )[0]
    };
  });

  // Calculate bounds to fit all regions
  const bounds = new window.google.maps.LatLngBounds();
  allRegions.forEach(region => {
    region.paths.forEach(coord => {
      bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
    });
  });

  const mapOptions = {
    mapTypeId: 'satellite',
    mapTypeControl: true,
    zoomControl: true,
    streetViewControl: true,
    fullscreenControl: true,
    styles: [
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#004358' }]
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{ color: '#1f8a70' }]
      },
    ]
  };

  const getPolygonOptions = useCallback((region) => ({
    fillColor: '#04BD9C',
    fillOpacity: selectedRegion?.id === region.id ? 0.6 : 
                hoveredRegion?.id === region.id ? 0.4 : 0.3,
    strokeColor: selectedRegion?.id === region.id ? '#038C73' : '#04BD9C',
    strokeOpacity: 1,
    strokeWeight: selectedRegion?.id === region.id ? 3 : 2,
    clickable: true,
    zIndex: selectedRegion?.id === region.id ? 2 : 1
  }), [selectedRegion, hoveredRegion]);

  // Handle map load to fit bounds only on initial load
  const onMapLoad = (map) => {
    mapRef.current = map;
    if (!boundsSet.current && allRegions.length > 0) {
      map.fitBounds(bounds);
      // Add a small delay before setting boundsSet to true
      setTimeout(() => {
        boundsSet.current = true;
      }, 1000);
    }
  };

  // Calculate center point of a polygon for label placement
  const getPolygonCenter = (paths) => {
    const bounds = new window.google.maps.LatLngBounds();
    paths.forEach(coord => {
      bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
    });
    return {
      lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
      lng: (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2
    };
  };

  // Format progress percentage
  const getProgressPercentage = (designated, ordered) => {
    if (!ordered) return 0;
    return Math.round((designated / ordered) * 100);
  };

  const infoWindowStyles = {
    container: {
      padding: '16px',
      maxWidth: '300px',
      fontFamily: 'Arial, sans-serif'
    },
    title: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '12px',
      color: '#2D3748',
      borderBottom: '2px solid #04BD9C',
      paddingBottom: '8px'
    },
    section: {
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      fontWeight: '600',
      color: '#4A5568',
      marginBottom: '4px'
    },
    value: {
      color: '#2D3748'
    },
    progressContainer: {
      marginTop: '8px',
      background: '#E2E8F0',
      borderRadius: '4px',
      height: '8px',
      overflow: 'hidden'
    },
    progressBar: (percentage) => ({
      width: `${percentage}%`,
      height: '100%',
      backgroundColor: '#04BD9C',
      transition: 'width 0.3s ease'
    }),
    progressText: {
      fontSize: '12px',
      color: '#718096',
      marginTop: '4px',
      textAlign: 'right'
    },
    description: {
      marginTop: '12px',
      padding: '8px',
      background: '#F7FAFC',
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '1.4'
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={mapOptions}
        onLoad={onMapLoad}
      >
        {allRegions.map((region) => (
          <React.Fragment key={region.id}>
            <Polygon
              paths={region.paths}
              options={getPolygonOptions(region)}
              onClick={() => {
                setSelectedRegion(region);
              }}
              onMouseOver={() => setHoveredRegion(region)}
              onMouseOut={() => setHoveredRegion(null)}
            />
            {/* Always show labels unless the region is selected */}
            {(!selectedRegion || selectedRegion.id !== region.id) && (
              <Marker
                position={getPolygonCenter(region.paths)}
                label={{
                  text: region.name,
                  color: '#FFFFFF',
                  fontSize: '14px',
                  fontWeight: 'bold'
                }}
                onClick={() => {
                  setSelectedRegion(region);
                }}
                options={{
                  clickable: true
                }}
              />
            )}
            {selectedRegion && selectedRegion.id === region.id && (
              <InfoWindow
                position={getPolygonCenter(region.paths)}
                onCloseClick={() => setSelectedRegion(null)}
              >
                <div style={infoWindowStyles.container}>
                  <h3 style={infoWindowStyles.title}>{region.name}</h3>
                  
                  <div style={infoWindowStyles.section}>
                    <span style={infoWindowStyles.label}>Organization</span>
                    <span style={infoWindowStyles.value}>
                      {region.organization?.name || 'Not specified'}
                    </span>
                  </div>

                  <div style={infoWindowStyles.section}>
                    <span style={infoWindowStyles.label}>Forest Type</span>
                    <span style={infoWindowStyles.value}>
                      {region.forest_type_name || 'Kenya Mangrove'}
                    </span>
                  </div>

                  {region.description && (
                    <div style={infoWindowStyles.description}>
                      <div dangerouslySetInnerHTML={{ __html: region.description }} />
                    </div>
                  )}
                </div>
              </InfoWindow>
            )}
          </React.Fragment>
        ))}
      </GoogleMap>
    </div>
  );
};

export default LocationMap;
